import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RouterOutlinedIcon from "@mui/icons-material/RouterOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EventSeatOutlinedIcon from "@mui/icons-material/EventSeatOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import SettingsEthernetOutlinedIcon from "@mui/icons-material/SettingsEthernetOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import AssignmentReturnedOutlinedIcon from "@mui/icons-material/AssignmentReturnedOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import SupportIcon from "@mui/icons-material/Support";
import CommitIcon from "@mui/icons-material/Commit";
import SchemaIcon from "@mui/icons-material/Schema";
import CodeIcon from "@mui/icons-material/Code";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import SendTimeExtensionOutlinedIcon from "@mui/icons-material/SendTimeExtensionOutlined";
import ModeOfTravelOutlinedIcon from "@mui/icons-material/ModeOfTravelOutlined";
import Diversity2OutlinedIcon from "@mui/icons-material/Diversity2Outlined";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";
import AllInclusiveOutlinedIcon from "@mui/icons-material/AllInclusiveOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";

class AppConstants {
  static PROJECT_PLATFORM = {
    muleSoft: "MULE_SOFT",
    tibco: "TIBCO",
    boomi: "BOOMI",
    nodejs: "NODE_JS",
    azure: "AZURE",
  };
  static INPUT_TYPE = {
    shipZip: "Migration",
    shipPrompt: "Greenfield",
    shipTrad: "Migration (Traditional)",
    shipDiscovery: "Discovery",
    shipMuleSoft: "MuleSoft",
  };
  static MESSAGE = {
    notEnoughInputs:
      "Processor couldn't process your request. Please provide more information.",
    errorDownload: "An error occurred while downloading the file.",
  };
  static NODE_TYPE = {
    activity: "activity",
    group: "group",
  };
  static PROJECT_STATUS = {
    Created: "Created",
    Completed: "Completed",
    InProgress: "In Progress",
    Failed: "Failed",
  };
  static PROJECT_TYPE = {
    Migration: "Migration",
    Greenfield: "Greenfield",
  };

  static BLOCKS = "blocks";
  static CODE = "code";
  static IconItems = [
    { name: "assign", icon: LanguageOutlinedIcon, className: "text-blue-500" },
    { name: "http", icon: LanguageOutlinedIcon, className: "text-blue-500" },
    { name: "read", icon: MarkUnreadChatAltIcon },
    { name: "router", icon: RouterOutlinedIcon },
    { name: "database", icon: SettingsEthernetOutlinedIcon },
    { name: "error", icon: ErrorOutlineIcon, className: "text-red-500" },
    { name: "call", icon: LocalPhoneOutlinedIcon },
    { name: "jdbc", icon: CommitIcon },
    { name: "code", icon: CodeIcon },
    { name: "json", icon: SchemaIcon },
    { name: "catch", icon: SupportIcon },
    { name: "event", icon: EventSeatOutlinedIcon },
    { name: "log", icon: InfoOutlinedIcon },
    { name: "logger", icon: InfoOutlinedIcon },
    { name: "exception", icon: InfoOutlinedIcon, className: "text-green-500" },
    { name: "security", icon: SecurityOutlinedIcon },
    { name: "documentation", icon: AssignmentOutlinedIcon },
    { name: "testing", icon: BugReportOutlinedIcon },
    { name: "documentation", icon: AssignmentOutlinedIcon },
    {
      name: "validations",
      icon: AdminPanelSettingsOutlinedIcon,
      className: "text-green-500",
    },
    { name: "validate", icon: AdminPanelSettingsOutlinedIcon },
    { name: "query", icon: AssignmentOutlinedIcon },
    { name: "null", icon: CancelOutlinedIcon, className: "text-red-500" },
    {
      name: "check",
      icon: AssignmentTurnedInOutlinedIcon,
      className: "text-blue-500",
    },
    {
      name: "get",
      icon: AssignmentReturnedOutlinedIcon,
      className: "text-blue-500",
    },
    {
      name: "close",
      icon: DisabledByDefaultOutlinedIcon,
      className: "text-red-500",
    },
    { name: "redec", icon: AppsOutlinedIcon },
    { name: "rest", icon: SendTimeExtensionOutlinedIcon },
    { name: "send", icon: SendTimeExtensionOutlinedIcon },
    { name: "map", icon: ModeOfTravelOutlinedIcon },
    { name: "rethrow", icon: AllInclusiveOutlinedIcon },
    { name: "parse", icon: AcUnitOutlinedIcon },
    { name: "invalid", icon: DangerousOutlinedIcon, className: "text-red-500" },
    { name: "group", icon: Diversity2OutlinedIcon },
    { name: "time", icon: AccessTimeOutlinedIcon },
    { name: "logMessage", icon: TextSnippetOutlinedIcon },
  ];
}

export default AppConstants;
