import React, { useState } from 'react';
import ProjectSubmissionWizard from '../createNewProject/ProjectSubmissionWizard';
import { Button } from '@mui/material';
import RecentProjects from './projects';
import CampaignsOverview from './campaigns';
import BlueprintsOverview from './blueprints';
import { useNavigate } from 'react-router-dom';
import MarketingHeader from './MarketingHeader';

const DashBoard = () => {
  const [createProjectVisible, setCreateProjectVisible] = useState(false);

  const onCreateProjectClick = () => setCreateProjectVisible(true);

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const navigateToCampaign = (campaignId) => {
    navigate(`/projects?campaignId=` + campaignId);
  };

  return (
    <div className="mx-auto p-2 mt-1 px-6">
      <div className="flex justify-between items-center mb-4">
        <div className=" text-2xl font-medium text-primary"></div>
      </div>
      <MarketingHeader />
      <div className="p-2">
        <div className="mb-12">
          <div className="flex justify-between items-center mt-8 mb-4">
            <div className="flex items-end gap-4">
              <div className="text-2xl font-medium text-primary">
                Recent Projects
              </div>
              <Button
                className="text-blue"
                onClick={() => handleNavigation('/projects')}
                size="small"
                color="info"
              >
                View more
              </Button>
            </div>
            <div className="">
              <Button
                variant="contained"
                onClick={onCreateProjectClick}
                size="small"
                className="btnSparkle h-[40px]"
              >
                Create Project
              </Button>
            </div>
          </div>
          <RecentProjects handleNavigation={handleNavigation} />
        </div>
        <div className="flex flex-row gap-16">
          <div className="mb-8 flex-1">
            <div className="flex justify-between items-center mb-1">
              <div className="text-md font-medium text-primary">Top Business Units</div>
              <Button
                className="text-secondary"
                onClick={() => handleNavigation('/campaigns')}
                size="small"
                color="info"
              >
                View more
              </Button>
            </div>

            <CampaignsOverview onRowViewClicked={navigateToCampaign} />
          </div>
          <div className="flex-[2]">
            <div className="flex justify-between items-center mb-1">
              <div className="text-md font-medium text-primary">
                Top Blueprints
              </div>
              <Button
                className="text-secondary"
                onClick={() => handleNavigation('/blueprints')}
                size="small"
                color="info"
              >
                View more
              </Button>
            </div>
            <BlueprintsOverview />
          </div>
        </div>
        {createProjectVisible && (
          <ProjectSubmissionWizard
            handleClose={() => setCreateProjectVisible(false)}
          />
        )}
      </div>
    </div>
  );
};

export default DashBoard;
