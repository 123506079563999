import React, { useState, useEffect, useRef } from "react";
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";

const DropdownWithAutoSuggestion = ({
  label,
  options,
  onSelect,
  selectedValue,
  placeholder = "Select",
  labelKey = "",
}) => {
  const [inputValue, setInputValue] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    setSelectedOption(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setShowOptions(true);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setShowOptions(false);
  };

  return (
    <div ref={dropdownRef}>
      <label
        id="listbox-label"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="relative">
        <button
          type="button"
          className="relative w-full cursor-default h-11 bg-white py-2.5 pl-3 pr-10 text-left text-gray-900 shadow-sm border-0 border-b-2 border-black focus:ring-2 focus:ring-secondary-500 sm:text-sm sm:leading-6"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          onClick={() => setShowOptions(!showOptions)}
        >
          <span className="flex items-center">
            <span className="ml-3 block truncate">
              {selectedOption[labelKey] || inputValue[labelKey] || placeholder}
            </span>
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
            <svg
              className="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>
        {showOptions && (
          <ul
            className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            tabIndex="-1"
            role="listbox"
            aria-labelledby="listbox-label"
          >
            <li
              className="text-gray-900 relative cursor-pointer select-none flex items-center py-2 pl-3 pr-9 hover:bg-secondary-50 border-b border-gray-200"
              role="option"
              onClick={() => handleSelectOption("ADD_NEW")}
            >
              <AddIcon className="h-5 w-5 text-secondary-600" />
              <span className="font-normal ml-3 block truncate">
                Add New Business Unit
              </span>
            </li>

            {options.map((option, index) => (
              <li
                key={index}
                className={`text-gray-900 relative cursor-pointer select-none flex items-center py-2 pl-3 pr-9 hover:bg-secondary-50 ${
                  option === selectedOption ? "bg-secondary-100" : ""
                }`}
                role="option"
                onClick={() => handleSelectOption(option)}
              >
                <div className="w-10">
                  {option[labelKey] === selectedOption[labelKey] && (
                    <DoneIcon className="h-5 w-5 text-secondary-600 mr-2" />
                  )}
                </div>
                <span className="font-normal block truncate">
                  {option[labelKey]}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DropdownWithAutoSuggestion;
